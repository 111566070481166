define("discourse/plugins/discourse-interstitials/discourse/controllers/admin-plugins-discourse-interstitial-index", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/decorators", "discourse-i18n"], function (_exports, _controller, _object, _service, _ajax, _ajaxError, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    dialog: (0, _service.service)(),
    router: (0, _service.service)(),
    showActive: true,
    editInterstitial(interstitial) {
      this.router.transitionTo("adminPlugins.discourse-interstitial.edit", interstitial.id);
    },
    goToReports() {
      this.router.transitionTo("adminReports.show", "discourse_interstitial");
    },
    toggleInactiveInterstitials() {
      this.set("showActive", !this.showActive);
    },
    interstitials(model, showActive) {
      return model.filterBy("enabled", showActive);
    },
    destroyInterstitial(interstitial) {
      this.dialog.confirm({
        message: (0, _discourseI18n.i18n)("discourse_interstitial.destroy_interstitial.confirm"),
        didConfirm: () => {
          interstitial.destroyRecord().then(() => this.send("triggerRefresh")).catch(_ajaxError.popupAjaxError);
        }
      });
    },
    cloneInterstitial(interstitial) {
      this.dialog.yesNoConfirm({
        message: (0, _discourseI18n.i18n)("discourse_interstitial.clone_interstitial.confirm"),
        didConfirm: () => {
          return (0, _ajax.ajax)(`/admin/plugins/discourse-interstitial/interstitials/clone`, {
            type: "POST",
            data: JSON.stringify({
              id: interstitial.id
            }),
            dataType: "json",
            contentType: "application/json"
          }).then(response => {
            this.router.transitionTo("adminPlugins.discourse-interstitial.edit", response.interstitial.id);
          }).catch(_ajaxError.popupAjaxError);
        }
      });
    }
  }, [["method", "editInterstitial", [_object.action]], ["method", "goToReports", [_object.action]], ["method", "toggleInactiveInterstitials", [_object.action]], ["method", "interstitials", [(0, _decorators.default)("model.@each.enabled", "showActive")]], ["method", "destroyInterstitial", [_object.action]], ["method", "cloneInterstitial", [_object.action]]]));
});