define("discourse/plugins/discourse-interstitials/discourse/components/fields/di-date-time-field", ["exports", "@ember/component", "@ember/object", "discourse/lib/decorators", "discourse/plugins/discourse-interstitials/discourse/components/fields/di-base-field", "@ember/template-factory"], function (_exports, _component, _object, _decorators, _diBaseField, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="field date-field">
    <div class="control-group">
      {{fields/di-field-label label=this.label field=this.field}}
  
      <div class="controls">
        <div class="controls-row">
          <input
            type="datetime-local"
            value={{readonly this.localTime}}
            disabled={{this.field.isDisabled}}
            {{on "input" (action "convertToUniversalTime" value="target.value")}}
          />
  
          {{#if this.field.metadata.value}}
            {{d-button
              icon="trash-alt"
              action=(action (mut this.field.metadata.value) value=null)
              disabled=this.field.isDisabled
            }}
          {{/if}}
  
          {{#if this.field.instructions}}
            <div class="control-instructions">
              <p class="help">{{this.field.instructions}}</p>
            </div>
          {{/if}}
        </div>
  
        {{fields/di-field-description description=this.description}}
      </div>
  
    </div>
  </section>
  */
  {
    "id": "uHTvFT2y",
    "block": "[[[10,\"section\"],[14,0,\"field date-field\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"label\",\"field\"],[[30,0,[\"label\"]],[30,0,[\"field\"]]]]]],[1,\"\\n\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"controls-row\"],[12],[1,\"\\n        \"],[11,\"input\"],[16,2,[28,[37,4],[[30,0,[\"localTime\"]]],null]],[16,\"disabled\",[30,0,[\"field\",\"isDisabled\"]]],[24,4,\"datetime-local\"],[4,[38,5],[\"input\",[28,[37,6],[[30,0],\"convertToUniversalTime\"],[[\"value\"],[\"target.value\"]]]],null],[12],[13],[1,\"\\n\\n\"],[41,[30,0,[\"field\",\"metadata\",\"value\"]],[[[1,\"          \"],[1,[28,[35,8],null,[[\"icon\",\"action\",\"disabled\"],[\"trash-alt\",[28,[37,6],[[30,0],[28,[37,9],[[30,0,[\"field\",\"metadata\",\"value\"]]],null]],[[\"value\"],[null]]],[30,0,[\"field\",\"isDisabled\"]]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"field\",\"instructions\"]],[[[1,\"          \"],[10,0],[14,0,\"control-instructions\"],[12],[1,\"\\n            \"],[10,2],[14,0,\"help\"],[12],[1,[30,0,[\"field\",\"instructions\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\\n      \"],[1,[28,[35,11],null,[[\"description\"],[[30,0,[\"description\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"section\",\"div\",\"fields/di-field-label\",\"input\",\"readonly\",\"on\",\"action\",\"if\",\"d-button\",\"mut\",\"p\",\"fields/di-field-description\"]]",
    "moduleName": "discourse/plugins/discourse-interstitials/discourse/components/fields/di-date-time-field.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _diBaseField.default.extend(dt7948.p({
    convertToUniversalTime(date) {
      return date && this.set("field.metadata.value", moment(date).utc().format());
    },
    localTime(value) {
      return value && moment(value).local().format(moment.HTML5_FMT.DATETIME_LOCAL);
    }
  }, [["method", "convertToUniversalTime", [_object.action]], ["method", "localTime", [(0, _decorators.default)("field.metadata.value")]]])));
});